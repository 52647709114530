import React from 'react';
import Callout from '../callout';

export default function index({ bodyCopy }) {
  return (
    <Callout calloutTitle="Prescriber Resources" buttonTitle="View Resources" buttonLink="/hcp/resources/" bodyCopy={bodyCopy}>
      <p>Download forms, access patient education materials, and more.</p>
    </Callout>
  )
}
