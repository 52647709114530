import React from 'react';
import Callout from '../callout';

export default function index({ bodyCopy }) {
  return (
    <Callout calloutTitle="Patient Savings Eligibility" buttonTitle="Learn About Eligibility<br/>for Your Patients" buttonLink="/hcp/paying-for-aveed/" bodyCopy={bodyCopy}>
      <p>Depending on your patients' insurance, and other relevant factors, they may be eligible for savings.</p>
    </Callout>
  )
}
