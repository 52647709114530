import React from "react";
import Callout from "../callout";
export default function index({ bodyCopy }) {
  return (
    <Callout
      calloutTitle={`Get Listed on AVEEDfinder`}
      buttonTitle="View AVEEDfinder Sign-up"
      buttonLink="/hcp/aveed-finder-signup/"
      isTargetBlank={false}
      bodyCopy={bodyCopy}
    >
      <p>Sign up with AVEEDfinder so patients can search for you.</p>
    </Callout>
  );
}
