import React from 'react';
import Callout from '../callout';

export default function index({ bodyCopy }) {
  return (
    <Callout calloutTitle="How to Administer AVEED<sup>&reg;</sup>" buttonTitle="View Procedure" buttonLink="/hcp/dosing-and-administration/" bodyCopy={bodyCopy}>
      <p>Read an overview of the injection procedure and other important facts about administering AVEED<sup>&reg;</sup>.</p>
    </Callout>
  )
}
