import React from "react";
import { PatientPage, SEO, HangingMary } from "components";
import { Row, Col } from 'react-flexbox-grid';
import { PatientBrochure, PatientSavingsCallout } from "components/shared/callouts";
import coupleSelfie from 'images/couple-selfie.png';

import './why-aveed.scss';

const pageDescription = "Read about AVEED® (testosterone undecanoate) injection CIII. Talk to your doctor to see if it may be the right treatment option for you.";

const WhyAveedPage = () => {
  return (
    <PatientPage pageClassName="why-aveed">
      <SEO pageTitle="Why AVEED®?" pageDescription={pageDescription} />
      <div className="dfa-container gutter-left gutter-right">
        <h1>Why AVEED<sup>&reg;</sup>?</h1>
        <Row>
          <Col xs={12}>
            <p>If you have hypogonadism (low or no testosterone), you may be talking with your doctor about controlling your testosterone levels.</p>
            <p className="txt-first-month"><span className="strong">5 SHOTS A YEAR</span> AFTER THE FIRST MONTH OF THERAPY</p>
          </Col>
        </Row>
        <Row className="ten-weeks-block">
          <Col xs={12} md={4}>
            <div className="text-ten-fullweeks">
              <p style={{ paddingBottom: 5 }}>
                AVERAGE TESTOSTERONE<br />
                LEVELS WITHIN THE<br />
                NORMAL RANGE FOR
              </p>
              <div className="weeks-block">
                <p className="numero">10</p>
                <p className="text">FULL<br />WEEKS</p>
              </div>
            </div>
            <p>In a clinical trial, 94% of men who received AVEED<sup>&reg;</sup> injections maintained average testosterone levels within the normal range for 10 full weeks after the third injection.* 5.1% of patients had testosterone levels below normal range, and 0.9% of patients had testosterone levels above normal range.</p>
            <p>The most common side effects of AVEED<sup>&reg;</sup> include acne, pain at the injection site, increased prostate specific antigen (a test used to screen for prostate cancer), increased estradiol level, low testosterone level, feeling tired, irritability, increased red blood cell count, difficulty sleeping, or mood swings.</p>
          </Col>
          <Col xs={12} md={8} className="selfie-block">
            <img src={coupleSelfie} alt="Couple taking selfie" />
            <span className="txt-not-actual">Not an actual patient.</span>
          </Col>
          <Col xs={12}>
            <HangingMary className="footnote" symbol="*" text={`AVEED<sup>&reg;</sup> was studied for 84 weeks in men with hypogonadism aged 18 years or older. Patients received AVEED<sup>&reg;</sup> 750 mg at baseline, at 4 weeks, and then every 10 weeks thereafter. 110 out of 117 patients (94%) maintained average testosterone concentrations in the normal range (300-1000 ng/dL) for 10 full weeks after the 3rd injection.`} />
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <h2>Avoid the risk of transference</h2>
            <p>Since AVEED<sup>&reg;</sup> is an injection that you receive at your doctor's office, you avoid the risk of transferring the medicine from your body to others.</p>
          </Col>
        </Row>

        <Row className="callout-blocks-container">
          <Col xs={12} md={6}>
            <PatientBrochure />
          </Col>
          <Col xs={12} md={6}>
            <PatientSavingsCallout />
          </Col>
        </Row>

      </div>
    </PatientPage>
  )
}

export default WhyAveedPage