import React from "react";
import { withPrefix } from "gatsby";
import Callout from "./callout";
export default function index({ bodyCopy }) {
  return (
    <Callout
      calloutTitle="Get the Conversation Started"
      buttonTitle="View Discussion Guide"
      buttonLink={withPrefix("/pdfs/doctor-discussion-guide.pdf")}
      isTargetBlank="_blank"
      bodyCopy={bodyCopy}
    >
      <p>
        Talking with your doctor about treatment options may be a little easier
        with the Doctor Discussion Guide.
      </p>
    </Callout>
  );
}
