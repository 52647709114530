import React from 'react';
import Callout from '../callout';

export default function index({ bodyCopy }) {
  return (
    <Callout calloutTitle="Get Started" buttonTitle="Find Out More" buttonLink="/hcp/get-aveed/?scrollto=access_and_reimbursement" bodyCopy={bodyCopy}>
      <p>Once you're certified, you're ready to order AVEED<sup>&reg;</sup> for your patients.</p>
    </Callout>
  )
}
