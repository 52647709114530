import React from 'react';
import Callout from './callout';
import { withPrefix } from 'gatsby'
export default function index({ bodyCopy }) {
  return (
    <Callout calloutTitle="View the Patient Brochure" buttonTitle="Download Brochure" buttonLink={withPrefix('/pdfs/aveed-patient-brochure.pdf')} isTargetBlank={true} bodyCopy={bodyCopy}>
      <p>Learn more about hypogonadism, treatment with AVEED<sup>&reg;</sup>, and how to save.</p>
    </Callout>
  )
}
