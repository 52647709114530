import React from "react";
import Callout from "./callout";
export default function index({ bodyCopy }) {
  return (
    <Callout
      calloutTitle={`Find AVEED<sup style="padding-right: 4px">&reg;</sup> Near You`}
      buttonTitle="View AVEEDfinder"
      buttonLink="/aveed-finder"
      isTargetBlank={false}
      bodyCopy={bodyCopy}
    >
      <p>
        Use AVEEDfinder to search for healthcare professionals certified to
        prescribe AVEED<sup>&reg;</sup> in your area.
      </p>
    </Callout>
  );
}
